import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContextMenu = _resolveComponent("ContextMenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: "block-diagram-element",
    style: _normalizeStyle(_ctx.cursorStyle)
  }, [
    (_ctx.menu.show)
      ? (_openBlock(), _createBlock(_component_ContextMenu, {
          key: 0,
          class: "block-diagram-menu",
          style: _normalizeStyle(_ctx.menuStyle),
          sections: _ctx.menuOptions,
          onSelect: _ctx.onItemSelect,
          onFocusout: _ctx.closeContextMenu
        }, null, 8, ["style", "sections", "onSelect", "onFocusout"]))
      : _createCommentVNode("", true)
  ], 4))
}