import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-566b8504"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon" }
const _hoisted_2 = ["onClick", "onMouseenter"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContextMenuListing = _resolveComponent("ContextMenuListing")!
  const _component_FocusBox = _resolveComponent("FocusBox")!

  return (_openBlock(), _createBlock(_component_FocusBox, {
    class: "title-bar-control",
    pointerEvent: "click",
    onFocusout: _ctx.menuClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("li", _hoisted_1, [
        _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu) => {
        return (_openBlock(), _createElementBlock("li", {
          key: menu.text,
          class: _normalizeClass({ active: _ctx.isActive(menu) }),
          onClick: ($event: any) => (_ctx.menuOpen(menu.text)),
          onMouseenter: ($event: any) => (_ctx.menuEnter(menu.text))
        }, [
          _createElementVNode("p", null, _toDisplayString(menu.text), 1),
          (_ctx.isActive(menu))
            ? (_openBlock(), _createBlock(_component_ContextMenuListing, {
                key: 0,
                class: "menu-listing",
                sections: menu.sections,
                onSelect: _ctx.menuSelect
              }, null, 8, ["sections", "onSelect"]))
            : _createCommentVNode("", true)
        ], 42, _hoisted_2))
      }), 128))
    ]),
    _: 3
  }, 8, ["onFocusout"]))
}