
import Configuration from "@/assets/configuration/builder.config";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "AppFooterBar",
  data(){
    return {
      fileName: Configuration.file_type_name
    }
  },
  computed: {

    /**
     * Application Store getters
     */
    ...mapGetters("ApplicationStore", [
        "hasSelection", "isValid"
    ])
    
  }
});
