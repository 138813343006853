
import * as Store from "@/store/StoreTypes";
// Dependencies
import { defineComponent } from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import * as App from "@/store/Commands/AppCommands";
import * as Page from "@/store/Commands/PageCommands";
import Debouncer from "@/assets/scripts/BlockDiagram/Utilities/Debouncer";
// Components
import Close from "@/components/Icons/Close.vue";
import DownArrow from "@/components/Icons/DownArrow.vue";
import UpArrow from "@/components/Icons/UpArrow.vue";

export default defineComponent({
    name: "FindDialog",
    data() {
        return {
            query: "",
            lastQuery: "",
            debouncer: new Debouncer(0.4),
            totalResults: 0,
            currentResultIndex: 0,
            currentDiagramObject: null as any,
        }
    },
    computed: {
        ...mapState("ApplicationStore", {
            ctx(state: Store.ApplicationStore): Store.ApplicationStore {
                return state;
            },
            editor(state: Store.ApplicationStore): Store.PageEditor {
                return state.activePage;
            }
        }),

        ...mapGetters("ApplicationStore", ["isShowingFindDialog", "currentFindResult"]),
    },
    components: {
        Close,
        DownArrow,
        UpArrow,
    },
    watch: {
        isShowingFindDialog: {
            handler(isShowingFindDialog, oldValue) {
                if (isShowingFindDialog) {
                    this.focus();
                } else {
                    this.blur();
                }
            }
        },
        currentFindResult: {
            handler(newResult, oldResult) {
                if (newResult !== null) {
                    this.currentResultIndex = newResult.index;
                    this.totalResults = newResult.totalResults;
                    if (this.currentDiagramObject !== newResult.diagramObject) {
                        this.currentDiagramObject = newResult.diagramObject;
                        this.execute(new Page.UnselectDescendants(this.editor.page));
                        this.execute(new Page.SelectObject(this.currentDiagramObject));
                        this.execute(new Page.MoveCameraToSelection(this.ctx, this.editor.page))
                    }
                } else {
                    this.totalResults = 0;
                }
            }
        }
    },
    methods: {
        /**
         * Application Store mutations
         */
        ...mapMutations("ApplicationStore", ["execute"]),

        /**
         * Focus the input field and highlight existing text
         */
        focus() {
            const queryInput = this.$refs.query as HTMLInputElement;
            queryInput.focus();
            queryInput.select();
        },

        /**
         * Blur the input field.
         */
        blur() {
            const queryInput = this.$refs.query as HTMLInputElement;
            queryInput.blur();
        },

        /**
         * Update find results with the current query.
         */
        runQuery(event: KeyboardEvent) {
            if (event.key === "Escape") {
                this.hideFindDialog();
            } else if (event.key === "Enter") {
                if (event.shiftKey) {
                    this.execute(new App.MoveToPreviousFindResult(this.ctx));
                } else {
                    this.execute(new App.MoveToNextFindResult(this.ctx));
                }
            } else if (this.query !== this.lastQuery) {
                this.debouncer.call(() => {
                    this.lastQuery = this.query;
                    this.ctx.finder.runQuery(this.ctx.activePage, this.query);
                });
            }
        },

        /**
         * Focus the next item in the result set.
         */
        findNext() {
            this.execute(new App.MoveToNextFindResult(this.ctx));
        },

        /**
         * Focus the previous item in the result set.
         */
        findPrevious() {
            this.execute(new App.MoveToPreviousFindResult(this.ctx));
        },

        /**
         * Hide the find dialog.
         */
        hideFindDialog() {
            this.execute(new App.HideFindDialog(this.ctx));
        },
    }
});
