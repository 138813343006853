
import { defineComponent } from "vue";
export default defineComponent({
    name: "Close",
    props: {
        color: {
            type: String,
            default: "#737373"
        }
    }
});
