import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "frame center" }
const _hoisted_2 = { class: "frame right" }
const _hoisted_3 = { class: "frame bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTitleBar = _resolveComponent("AppTitleBar")!
  const _component_FindDialog = _resolveComponent("FindDialog")!
  const _component_BlockDiagram = _resolveComponent("BlockDiagram")!
  const _component_SplashMenu = _resolveComponent("SplashMenu")!
  const _component_EditorSidebar = _resolveComponent("EditorSidebar")!
  const _component_AppFooterBar = _resolveComponent("AppFooterBar")!
  const _component_AppHotkeyBox = _resolveComponent("AppHotkeyBox")!

  return (_openBlock(), _createBlock(_component_AppHotkeyBox, { id: "main" }, {
    default: _withCtx(() => [
      _createVNode(_component_AppTitleBar, { id: "app-title-bar" }),
      _createVNode(_component_FindDialog, {
        ref: "findDialog",
        id: "find-dialog",
        style: _normalizeStyle(_ctx.findDialogLayout)
      }, null, 8, ["style"]),
      _createElementVNode("div", {
        id: "app-body",
        ref: "body",
        style: _normalizeStyle(_ctx.gridLayout)
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_BlockDiagram, { id: "block-diagram" }),
          (_ctx.isShowingSplash)
            ? (_openBlock(), _createBlock(_component_SplashMenu, {
                key: 0,
                id: "splash-menu"
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "resize-handle",
            onPointerdown: _cache[0] || (_cache[0] = ($event: any) => (_ctx.startResize($event, _ctx.Handle.Right)))
          }, null, 32),
          _createVNode(_component_EditorSidebar, { id: "app-sidebar" })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_AppFooterBar, { id: "app-footer-bar" })
        ])
      ], 4)
    ]),
    _: 1
  }))
}