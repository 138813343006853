import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withModifiers as _withModifiers, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fac84594"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "options-container" }
const _hoisted_2 = { class: "value" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OptionsList = _resolveComponent("OptionsList")!
  const _component_FocusBox = _resolveComponent("FocusBox")!

  return (_openBlock(), _createBlock(_component_FocusBox, {
    class: _normalizeClass(['text-field-control', { disabled: _ctx.disabled }]),
    tabindex: _ctx.tabIndex,
    pointerEvent: "click",
    onFocusin: _ctx.onFocusIn,
    onFocusout: _ctx.onFocusOut
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.select !== null)
          ? (_openBlock(), _createBlock(_component_OptionsList, {
              key: 0,
              class: "options-list",
              select: _ctx.select,
              options: _ctx.suggestions,
              maxHeight: _ctx.maxHeight,
              onSelect: _ctx.updatePropertyFromSuggestion
            }, null, 8, ["select", "options", "maxHeight", "onSelect"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("textarea", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
          ref: "field",
          placeholder: "Null",
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
          onKeyup: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
          onKeydown: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onKeyDown && _ctx.onKeyDown(...args)), ["stop"])),
          disabled: _ctx.disabled
        }, null, 40, _hoisted_3), [
          [_vModelText, _ctx.value]
        ])
      ])
    ]),
    _: 1
  }, 8, ["class", "tabindex", "onFocusin", "onFocusout"]))
}