import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-de21f546"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "results" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UpArrow = _resolveComponent("UpArrow")!
  const _component_DownArrow = _resolveComponent("DownArrow")!
  const _component_Close = _resolveComponent("Close")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["find-dialog-container", {
        hidden:
            !_ctx.isShowingFindDialog
    }])
  }, [
    _withDirectives(_createElementVNode("input", {
      type: "text",
      class: "query",
      placeholder: "Find",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
      onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.runQuery && _ctx.runQuery(...args))),
      ref: "query"
    }, null, 544), [
      [_vModelText, _ctx.query]
    ]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.totalResults === 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "No results"))
        : _createCommentVNode("", true),
      (_ctx.totalResults !== 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.currentResultIndex + 1) + " of " + _toDisplayString(_ctx.totalResults), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["control", {
            disabled:
                _ctx.totalResults === 0
        }]),
      onPointerdown: _cache[2] || (_cache[2] = ($event: any) => (_ctx.findPrevious()))
    }, [
      _createVNode(_component_UpArrow)
    ], 34),
    _createElementVNode("div", {
      class: _normalizeClass(["control", {
            disabled:
                _ctx.totalResults === 0
        }]),
      onPointerdown: _cache[3] || (_cache[3] = ($event: any) => (_ctx.findNext()))
    }, [
      _createVNode(_component_DownArrow)
    ], 34),
    _createElementVNode("div", {
      class: "control",
      onPointerdown: _cache[4] || (_cache[4] = ($event: any) => (_ctx.hideFindDialog()))
    }, [
      _createVNode(_component_Close, { color: "#8c8c8c" })
    ], 32)
  ], 2))
}