
// Dependencies
import { defineComponent, PropType } from 'vue';
import { ContextMenu } from "@/assets/scripts/ContextMenuTypes";
// Components
import FocusBox from "@/components/Containers/FocusBox.vue";
import ContextMenuListing from "./ContextMenuListing.vue";

export default defineComponent({
  name: 'TitleBar',
  props: {
    menus: {
      type: Array as PropType<ContextMenu[]>,
      default: []
    }
  },
  data() {
    return {
      activeMenu: null as string | null
    }
  },
  emits: ["select"],
  methods: {

    /**
     * Tests if a menu is currently active.
     * @param menu
     *  The context menu.
     * @returns
     *  True if the menu is active, false otherwise.
     */
    isActive(menu: ContextMenu): boolean {
      return menu.text === this.activeMenu;
    },
    
    /**
     * Menu selection behavior.
     * @param id
     *  The id of the selected menu.
     */
    menuOpen(id: string) {
      this.activeMenu = id;
    },

    /**
     * Menu mouse enter behavior.
     * @param id
     *  The id of the hovered menu.
     */
    menuEnter(id: string) {
      if(this.activeMenu === null)
        return;
      this.activeMenu = id;
    },

    /**
     * Menu close behavior.
     */
    menuClose() {
      this.activeMenu = null;
    },

    /**
     * Menu item selection behavior.
     * @param data
     *  The menu item's data.
     */
    menuSelect(data: any) {
      this.$emit("select", data);
    }

  },
  components: { FocusBox, ContextMenuListing }
});
