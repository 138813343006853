
const Images = require.context("../../assets/configuration", false);
import * as App from "@/store/Commands/AppCommands";
import * as Store from "@/store/StoreTypes";
import Configuration from "@/assets/configuration/builder.config"
// Dependencies
import { version } from "@/../package.json";
import { defineComponent } from 'vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
// Components
import Link from "@/components/Icons/Link.vue";
import Folder from "@/components/Icons/Folder.vue";
import FullPage from "@/components/Icons/FullPage.vue";
import EmptyPage from "@/components/Icons/EmptyPage.vue";
import ScrollBox from "../Containers/ScrollBox.vue";

export default defineComponent({
  name: 'SplashMenu',
  data() {
    let organization;
    if (Configuration.splash.organization) {
      organization = Images(Configuration.splash.organization);
    }
    return {
      applicationName: Configuration.application_name,
      applicationVersion: version,
      organization,
      newFile: Configuration.splash.new_file,
      openFile: Configuration.splash.open_file,
      helpLinks: Configuration.splash.help_links
    }
  },
  computed: {

    /**
     * Application Store data
     */
    ...mapState("ApplicationStore", {
      context(state: Store.ApplicationStore): Store.ApplicationStore {
        return state;
      },
      recoveredPages(state: Store.ApplicationStore): Map<string, { name: string, file: string }> {
        return state.recoveryBank.pages;
      }
    }),

    /**
     * Returns the application's recovered pages.
     * @returns
     *  The application's recovered pages.
     */
    pages(): Map<string, { name: string, date: string, file: string }> {
      let pages = new Map();
      for(let [key, page] of this.recoveredPages) {
        let parse = /^((?:.|\n)*)\s{1}\((.*)\)$/m.exec(page.name);
        let file = page.file;
        let name = parse ? parse[1] : "Unknown";
        let date = parse ? parse[2] : "Unknown";
        pages.set(key, { name, date, file });
      }
      return pages;
    }
    
  },
  methods: {

    /**
     * Application Store mutations
     */
    ...mapMutations("ApplicationStore", ["execute"]),

    /**
     * New File behavior.
     */
    async onNewFile() {
      this.execute(await App.PrepareEditorWithFile.fromNew(this.context));
    },

    /**
     * Open File behavior.
     */
    async onOpenFile() {
      this.execute(await App.PrepareEditorWithFile.fromFileSystem(this.context));
    },

    /**
     * Recover File behavior.
     * @param id
     *  The file to recover.
     */
    async onRecoverFile(file: string) {
      this.execute(await App.PrepareEditorWithFile.fromFile(this.context, file));
    },

    /**
     * Delete Recovered File behavior.
     * @param id
     *  The id of the file to delete.
     */
    onDeleteFile(id: string) {
      this.execute(new App.DeletePageFromRecoveryBank(this.context, id));
    },

    /**
     * Open help behavior.
     * @param url
     *  The url to open.
     */
    onOpenHelp(url: string) {
      this.execute(new App.OpenHyperlink(this.context, url));
    }

  },
  components: { Link, Folder, FullPage, EmptyPage, ScrollBox },
});
