import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-028344ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logo" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleBar = _resolveComponent("TitleBar")!

  return (_openBlock(), _createBlock(_component_TitleBar, {
    class: "app-title-bar-element",
    menus: _ctx.menus,
    onSelect: _ctx.onItemSelect
  }, {
    icon: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createElementVNode("img", {
          alt: "Logo",
          title: "Logo",
          src: _ctx.icon
        }, null, 8, _hoisted_2)
      ])
    ]),
    _: 1
  }, 8, ["menus", "onSelect"]))
}