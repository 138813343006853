import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withModifiers as _withModifiers, vModelText as _vModelText, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59c6bfe0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "options-container" }
const _hoisted_2 = { class: "value-container" }
const _hoisted_3 = {
  key: 0,
  class: "dropdown-arrow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OptionsList = _resolveComponent("OptionsList")!
  const _component_FocusBox = _resolveComponent("FocusBox")!

  return (_openBlock(), _createBlock(_component_FocusBox, {
    class: _normalizeClass(['enum-field-control', { disabled: _ctx.disabled }]),
    tabindex: _ctx.tabIndex,
    pointerEvent: "click",
    onFocusin: _ctx.onFocusIn,
    onFocusout: _ctx.onFocusOut
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.showMenu)
          ? (_openBlock(), _createBlock(_component_OptionsList, {
              key: 0,
              class: "options-list",
              select: _ctx.select,
              options: _ctx.options,
              maxHeight: _ctx.maxHeight,
              onSelect: _ctx.updateProperty
            }, null, 8, ["select", "options", "maxHeight", "onSelect"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("div", {
          class: _normalizeClass(['value-text', { 'is-null': _ctx.isNull }])
        }, _toDisplayString(_ctx.selectText), 3), [
          [_vShow, !_ctx.showSearch]
        ]),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          ref: "search",
          class: "value-search",
          placeholder: "Search",
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSearchInput && _ctx.onSearchInput(...args))),
          onKeyup: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
          onKeydown: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSearchKeyDown && _ctx.onSearchKeyDown(...args)), ["stop"])),
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.searchTerm) = $event))
        }, null, 544), [
          [_vModelText, _ctx.searchTerm],
          [_vShow, _ctx.showSearch]
        ]),
        (!_ctx.disabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, "▼"))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["class", "tabindex", "onFocusin", "onFocusout"]))
}