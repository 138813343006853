
import { defineComponent } from "vue";

export default defineComponent({ 
  name: "EmptyPage",
  props: {
    color: {
      type: String,
      default: "#89a0ec"
    }
  }
});
