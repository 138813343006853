
import { Browser, OperatingSystem } from '@/assets/scripts/Browser';
import { defineComponent, PropType } from 'vue';
import { 
  ContextMenu, ContextMenuItem, 
  ContextMenuSection, MenuType
} from "@/assets/scripts/ContextMenuTypes";

const KeyToTextWin: { [key: string]: string } = {
  Control    : "Ctrl",
  Escape     : "Esc",
  ArrowLeft  : "←",
  ArrowUp    : "↑",
  ArrowRight : "→",
  ArrowDown  : "↓",
  Delete     : "Del",
  Meta       : "Win"
}

const KeyToTextMacOS: { [key: string]: string } = {
  Control    : "⌃",
  Escape     : "Esc",
  ArrowLeft  : "←",
  ArrowUp    : "↑",
  ArrowRight : "→",
  ArrowDown  : "↓",
  Delete     : "Del",
  Meta       : "⌘",
  Shift      : "⇧",
  Alt        : "⌥"
}

export default defineComponent({
  name: 'ContextMenuListing',
  props: {
    root: {
      type: Boolean,
      default: true,
    },
    sections: {
      type: Array as PropType<ContextMenuSection[]>,
      required: true
    },
    forceInsideWindow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      xOffset: 0,
      yOffset: 0,
      activeSubMenu: null as string | null,
      leaveTimeout: 500,
      leaveTimeoutId: 0,
      MenuType
    }
  },
  computed: {

    /**
     * Returns the ContextMenuListing's offset styling.
     * @returns
     *  The ContextMenuListing's offset styling.
     */
    offset(): { marginTop: string, marginLeft: string } {
      return {
        marginTop: `${ this.yOffset }px`,
        marginLeft: `${ this.xOffset }px`
      }
    }

  },
  emits: ["select", "_select"],
  methods: {
    
    /**
     * Tests is a submenu is active.
     * @param menu
     *  The context submenu.
     * @returns
     *  True if the submenu is active, false otherwise.
     */
    isActive(menu: ContextMenu) {
      return menu.text === this.activeSubMenu;
    },

    /**
     * Submenu mouse enter behavior.
     * @param menu
     *  The hovered submenu.
     */
    submenuEnter(menu: ContextMenu) {
      if(!menu.disabled) {
        clearTimeout(this.leaveTimeoutId);
        this.activeSubMenu = menu.text;
      }
    },

    /**
     * Submenu mouse leave behavior.
     * @param menu
     *  The unhovered submenu.
     */
    submenuLeave(menu: ContextMenu) {
      if(!menu.disabled) {
        this.leaveTimeoutId = setTimeout(() => {
          this.activeSubMenu = null;
        }, this.leaveTimeout)
      }
    },

    /**
     * Menu item selection behavior.
     * @param item
     *  The selected menu item.
     */
    onItemClick(item: ContextMenuItem) {
      if(!item.disabled) {
        if(this.root) {
          this.$emit("select", item.data);
        } else {
          this.$emit("_select", item);
        }
      }
    },

    /**
     * Submenu item selection behavior.
     * @param item
     *  The selected menu item.
     */
    onChildItemSelect(item: ContextMenuItem) {
      if(this.root) {
        this.$emit("select", item.data);
      } else {
        this.$emit("_select", item);
      }
      if(!item.keepMenuOpenOnSelect) {
        this.activeSubMenu = null;
      }
    },

    /**
     * Formats a keyboard shortcut.
     * @param shortcut
     *  The keyboard shortcut to format.
     * @returns
     *  The formatted keyboard shortcut.
     */
    formatShortcut(shortcut?: string): string | undefined {
      if(!shortcut) {
        return shortcut;
      } else {
        if(Browser.getOperatingSystemClass() === OperatingSystem.MacOS) {
          return shortcut
            .split("+")
            .map(c => c in KeyToTextMacOS ? KeyToTextMacOS[c] : c)
            .join("")
        } else {
          return shortcut
            .split("+")
            .map(c => c in KeyToTextWin ? KeyToTextWin[c] : c)
            .join("+");
        }
      }
    }

  },
  mounted() {
    if(!this.forceInsideWindow) return;
    // Offset submenu if outside of viewport
    let viewWidth  = window.innerWidth;
    let viewHeight = window.innerHeight;
    let { top, left, bottom, right } = this.$el.getBoundingClientRect();
    this.xOffset = right > viewWidth ? -Math.min(left, right - viewWidth) : 0;
    this.yOffset = bottom > viewHeight ? -Math.min(top, bottom - viewHeight) : 0;
  }
});
