import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e452132"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["tabindex"]
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 0,
  class: "increment-arrows"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['number-field-control', { disabled: _ctx.disabled }]),
    tabindex: _ctx.tabIndex,
    onFocus: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onFocus()))
  }, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      type: "text",
      ref: "field",
      placeholder: "Null",
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
      onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onKeyDown && _ctx.onKeyDown(...args))),
      onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
      disabled: _ctx.disabled
    }, null, 40, _hoisted_2), [
      [_vModelText, _ctx.value]
    ]),
    (!_ctx.disabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "up-arrow",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateProperty(+1)))
          }, "▲"),
          _createElementVNode("div", {
            class: "down-arrow",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateProperty(-1)))
          }, "▼")
        ]))
      : _createCommentVNode("", true)
  ], 42, _hoisted_1))
}