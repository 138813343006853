import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52fa64a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "options"
}
const _hoisted_2 = ["list-id", "onClick", "onMouseenter"]
const _hoisted_3 = {
  key: 1,
  class: "no-options"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScrollBox = _resolveComponent("ScrollBox")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['options-list-field-control', { flip: _ctx.flip }])
  }, [
    _createVNode(_component_ScrollBox, {
      top: _ctx.scrollTop,
      style: _normalizeStyle(_ctx.style),
      propagateScroll: false
    }, {
      default: _withCtx(() => [
        (_ctx.hasOptions)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                return (_openBlock(), _createElementBlock("li", {
                  ref_for: true,
                  ref: "items",
                  key: option.value ?? 0,
                  "list-id": option.value,
                  class: _normalizeClass({ active: _ctx.isActive(option), null: _ctx.isNull(option) }),
                  onClick: ($event: any) => (_ctx.$emit('select', option.value)),
                  onMouseenter: ($event: any) => (_ctx.active = option.value),
                  "exit-focus-box": ""
                }, _toDisplayString(option.text), 43, _hoisted_2))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (!_ctx.hasOptions)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, " No matches "))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["top", "style"])
  ], 2))
}