import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8472a79c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["tabindex"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "focus-box-container",
    tabindex: _ctx.tabindex,
    onFocusin: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFocusIn && _ctx.onFocusIn(...args))),
    onFocusout: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocusOut && _ctx.onFocusOut(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 40, _hoisted_1))
}